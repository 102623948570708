<template>
  <div>
    <el-page-header @back="goBack" content="Users">
    </el-page-header>
    <el-form ref="form" :model="formModel" :inline="true" @submit.native.prevent>
        <form-gen :form-desc="formDesc" :model="formModel" />
        <el-form-item>
          <el-button type="primary" @click="onSubmit">Submit</el-button>
        </el-form-item>
      </el-form>
    <el-table
      v-loading="loading"
      :data="tableData"
      height="calc(100vh - 200px)"
      style="width: 100%">
      <el-table-column
        prop="masquerade"
        label="Masquerade"
        width="180">
          <template #default="scope">
            <div class="name-wrapper">
              <el-button type="primary" @click="masquerade(scope.row.masquerade)">Masquerade</el-button>
            </div>
          </template>
      </el-table-column>
      <el-table-column
        prop="name"
        label="Name"
        width="100">
      </el-table-column>
      <el-table-column
        prop="email"
        label="Email">
      </el-table-column>
      <el-table-column
        prop="customer.name"
        label="Customer"
        width="250">
        <template #default="scope">
          <router-link :to="{
            name: 'customers-edit',
            params: {id: scope.row.customer.id } }"
          >
            <el-button type="primary">
              {{ scope.row.customer.name }}
            </el-button>
          </router-link>
        </template>
      </el-table-column>
      <el-table-column
        prop="role"
        label="Role"
        width="80">
      </el-table-column>
      <el-table-column
        prop="dateJoined"
        label="Date joined"
        width="180">
      </el-table-column>
    </el-table>
  </div>
</template>

<script lang="ts">
import { Options, mixins } from 'vue-class-component'

import FormGen from '@/components/FormGen.vue'
import ListView from '@/components/ListView.vue'
import { HttpError } from '@/types/errors'
import { InputKind, FormDesc } from '@/types/form'
import { User, UserRow } from '@/types/userState'

@Options({
  components: {
    FormGen
  }
})
export default class Users extends mixins(ListView) {
  private formDesc: FormDesc = {
    nonFieldErrors: null,
    fieldsets: [{
      fields: [{
        disabled: false,
        errors: null,
        kind: InputKind.InputText,
        label: 'Search',
        name: 'search'
      }]
    }]
  }

  get modelData (): Array<User> | null {
    return this.$store.getters[this.getPath('get', 'list')]
  }

  get tableData (): Array<UserRow> {
    const table: Array<UserRow> = []
    if (this.modelData) {
      this.modelData.forEach((user: User) => {
        table.push({
          masquerade: user.id,
          name: `${user.firstName} ${user.lastName} `,
          email: user.email,
          customer: user.customer,
          role: user.role,
          dateJoined: user.dateJoined
        })
      })
    }

    return table
  }

  masquerade (id: string): void {
    this.$store.dispatch('users/masquerade', { id: id })
      .then(() => {
        const token: string = this.$store.getters['users/getMasqueradeToken']
        const url: string = `${process.env.VUE_APP_CMS_URL}m/#${token}`
        const elt:HTMLAnchorElement = document.createElement('a')
        elt.href = url
        // elt.target = '_blank'
        document.body.appendChild(elt)
        elt.click()

        document.body.removeChild(elt)
      })
      .catch((e: HttpError) => {
        this.setErrors(e)
      })
  }
}
</script>
