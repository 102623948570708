
import { Options, mixins } from 'vue-class-component'

import FormGen from '@/components/FormGen.vue'
import ListView from '@/components/ListView.vue'
import { HttpError } from '@/types/errors'
import { InputKind, FormDesc } from '@/types/form'
import { User, UserRow } from '@/types/userState'

@Options({
  components: {
    FormGen
  }
})
export default class Users extends mixins(ListView) {
  private formDesc: FormDesc = {
    nonFieldErrors: null,
    fieldsets: [{
      fields: [{
        disabled: false,
        errors: null,
        kind: InputKind.InputText,
        label: 'Search',
        name: 'search'
      }]
    }]
  }

  get modelData (): Array<User> | null {
    return this.$store.getters[this.getPath('get', 'list')]
  }

  get tableData (): Array<UserRow> {
    const table: Array<UserRow> = []
    if (this.modelData) {
      this.modelData.forEach((user: User) => {
        table.push({
          masquerade: user.id,
          name: `${user.firstName} ${user.lastName} `,
          email: user.email,
          customer: user.customer,
          role: user.role,
          dateJoined: user.dateJoined
        })
      })
    }

    return table
  }

  masquerade (id: string): void {
    this.$store.dispatch('users/masquerade', { id: id })
      .then(() => {
        const token: string = this.$store.getters['users/getMasqueradeToken']
        const url: string = `${process.env.VUE_APP_CMS_URL}m/#${token}`
        const elt:HTMLAnchorElement = document.createElement('a')
        elt.href = url
        // elt.target = '_blank'
        document.body.appendChild(elt)
        elt.click()

        document.body.removeChild(elt)
      })
      .catch((e: HttpError) => {
        this.setErrors(e)
      })
  }
}
