
import { Options, mixins } from 'vue-class-component'
import { Watch } from 'vue-property-decorator'

import FormGen from '@/components/FormGen.vue'
import ModelView from '@/components/ModelView.vue'
import { FormModel } from '@/types/form'
import { HttpError } from '@/types/errors'

@Options({
  components: {
    FormGen
  }
})
export default class ListView extends mixins(ModelView) {
  public formModel: FormModel = {
    search: ''
  }

  get loading () {
    if (this.modelData === null) {
      return true
    }
    return false
  }

  get modelData (): Array<any> | null {
    return []
  }

  fetchList (): void {
    this.$store.dispatch(this.getPath('fetch', 'list'), {
      search: this.formModel.search
    })
      .catch((e: HttpError) => {
        this.setErrors(e)
      })
  }

  onSubmit () {
    this.$router.push({
      name: this.getCurrentRouteName(),
      query: { search: this.formModel.search }
    })
    this.$store.commit(this.getPath('set', 'list'), null)
    this.fetchList()
  }

  created () {
    if (this.$route.query.search) {
      this.formModel.search = this.$route.query.search
    }
    this.fetchList()
  }

  @Watch('$route')
  watchRoute () {
    if (this.$route.query.search) {
      this.formModel.search = this.$route.query.search
    }
    this.fetchList()
  }

  beforeDestroy () {
    this.$store.commit(this.getPath('set', 'list'), null)
  }
}
